<style>
  .container {
    width: 100% !important;
    position: static !important;
  }

  .invoiceinput {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 4px;
    padding: 5px 10px;
  }

  .bold {
    font-weight: bold;
  }

  .paddingbottom {
    padding-bottom: 15px;
  }

  .margintop {
    margin-top: 25px;
  }

  mat-icon {
    position: relative;
    top: -2px;
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }

  .dialogbutton {
    height: 42px;
    width: 100%;
    cursor: pointer;
  }
  /*
  .material-icons {
    font-size: 16px;
  }*/

  button .mat-icon {
    font-size: 16px;
  }

  .error-tooltip {
    font-size: 16px;
    padding-top: 3px;
  }

  .error-message {
    color: red;
    font-size: 80%;
    margin-bottom: 15px;
  }

  .warning-box {
    border: 1px solid red;
    background-color: #ffe5e5; /* Light red background */
    padding: 20px;
    margin: 10px auto 20px auto;
    font-weight: bold;
  }

</style>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h3 *ngIf="!invoice.SubInvoice" class="main-heading">FAKTURAVERKTYGET <span *ngIf="invoice?.CreditInvoiceFor != null" style="color:red;">KREDITFAKTURA</span></h3>
      <h3 *ngIf="invoice.SubInvoice" class="main-heading">Underlag för din löneutbetalning </h3>

      <div class="warning-box" *ngIf="Suspended">
        <span>Ditt konto är spärrat pga misstänkt missbruk eller brott mot våra villkor.<br /> Kontakta supporten för att låsa upp kontot. Ring 08 - 51 81 64 40.</span>
      </div>

    </div>
  </div>
  <div class="row align-items-end" style="background-color: #f3f3f3; padding-top: 20px; padding-bottom: 30px;">

    <div class="col-sm-12 d-flex justify-content-start">
      <h2 class="accent">1. Kund </h2>
      <mat-icon class="descriptionIcon" color="accent">assignment_ind</mat-icon>
    </div>

    <div *ngIf="!invoice.SubInvoice" class="col-sm-12 col-md-8 col-lg-6 select-customer">
      <mat-card class="select-customer-card">
        <mat-card-title>Välj en kund<mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Om mottagaren är privatperson så behöver inte referens finnas med">info</mat-icon></mat-card-title>

        <!-- Select customer -->
        <div class="row">
          <div class="col-8">

            <mat-form-field style="width:100%;">
              <mat-select [disabled]="!canUpdateGeneral() || companies.length == 0" name="companies" placeholder="Ange mottagare" [(ngModel)]="invoice?.Customer.Id" (ngModelChange)="customerChange($event); updateTotalInvoiceAmount()">
                <mat-option *ngFor="let c of companies" [value]="c.Id">
                  {{ c.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-4">

            <anms-big-input-action icon="person_add" color="accent"
                                   (action)="addCustomer()"
                                   [disabled]="!canUpdateGeneral()"
                                   matTooltip="Lägg till ny kund" matTooltipPosition="before">
            </anms-big-input-action>

            <anms-big-input-action *ngIf="invoice?.Customer.Id > 0" icon="edit" color="accent"
                                   (action)="editCustomer()"
                                   [disabled]="!canUpdateGeneral() || invoice?.Customer.Global"
                                   matTooltip="Redigera" matTooltipPosition="before">
            </anms-big-input-action>
          </div>
        </div>
        <!-- Select reference person -->
        <div class="row">
          <div class="col-8">
            <mat-form-field *ngIf="invoice.Customer.IsCompany" style="width:100%;">
              <mat-select [disabled]="!canUpdateGeneral() || contacts == null || contacts.length == 0" placeholder="Din kontaktperson*" [(ngModel)]="invoice?.Reference.Id" (ngModelChange)="contactChange($event)">
                <mat-option *ngFor="let c of contacts" [value]="c.Id">
                  {{ c.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <anms-big-input-action icon="person_add" color="accent"
                                   *ngIf="invoice?.Customer.Id > 0"
                                   (action)="addContact()"
                                   [disabled]="!canUpdateGeneral()"
                                   [hidden]="!invoice.Customer.IsCompany"
                                   matTooltip="Lägg till ny kontakt" matTooltipPosition="before">
            </anms-big-input-action>
            <anms-big-input-action *ngIf="invoice?.Reference.Id > 0" icon="edit" color="accent"
                                   (action)="editContact(invoice.Reference)"
                                   [disabled]="!canUpdateGeneral()"
                                   matTooltip="Redigera" matTooltipPosition="before">
            </anms-big-input-action>
          </div>
        </div>

      </mat-card>
    </div>

    <!-- Fryst kundkort-->
    <div *ngIf="invoice?.Customer.Id > 0 && invoice?.CustomerCopy?.Name != null" class="col-sm-12 col-md-8 col-lg-6">
      <mat-card style="height: 250px; width: 100%;">
        <div>
          <h3>{{invoice?.CustomerCopy?.Name}} <mat-icon color="accent" style="top: 5px; height: 20px; width: 20px; font-size: 30px;">person</mat-icon><mat-icon *ngIf="isAdmin" style="color: green; top: 5px; height: 20px; width: 20px; font-size: 30px; float: right;" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Denna kundinfo är låst till fakturan">verified</mat-icon><br /><br /></h3>
          <div *ngIf="invoice.CustomerCopy.Co != null && invoice.CustomerCopy.Co != ''">
            C/o: {{invoice.CustomerCopy.Co}}<br />
            {{invoice.CustomerCopy.Reference}}
          </div>
          {{invoice?.CustomerCopy?.Address}}<br />
          {{invoice?.CustomerCopy?.Zip}} {{invoice?.CustomerCopy?.City}}<br />
          {{invoice?.CustomerCopy?.Country?.Name}}<br /><br />
          <span *ngIf="invoice?.Customer?.OrgNumber != ''">{{invoice?.CustomerCopy?.OrgNumber}}</span>
          <span *ngIf="invoice?.Customer?.PersonalNumber != ''">{{invoice?.CustomerCopy?.PersonalNumber}}</span>
        </div>        
      </mat-card>
    </div>
    <!-- /Fryst kundkort-->

    <!--Redigerbart kundkort-->
    <div *ngIf="invoice?.Customer.Id > 0 && invoice?.CustomerCopy?.Name == null" class="col-sm-12 col-md-8 col-lg-6">
      <mat-card style="height: 250px; width: 100%;">
        <div>
          <h3>{{invoice?.Customer?.Name}} <mat-icon color="accent" style="top: 5px; height: 20px; width: 20px; font-size: 30px;">person</mat-icon><mat-icon *ngIf="isAdmin" style="color: grey; top: 5px; height: 20px; width: 20px; font-size: 30px; float: right;" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Denna kundinfo är inte låst till fakturan">verified</mat-icon><br /><br /></h3>
          <div *ngIf="invoice.Customer.Co != null && invoice.Customer.Co != ''">
            c/o: {{invoice.Customer.Co}}<br />
            {{invoice.Customer.Reference}}
          </div>
          {{invoice?.Customer?.Address}}<br />
          {{invoice?.Customer?.Zip}} {{invoice?.Customer?.City}}<br />
          {{invoice?.Customer?.Country?.Name}}<br /><br />
          <span *ngIf="invoice?.Customer?.OrgNumber != ''">{{invoice?.Customer?.OrgNumber}}</span>
          <span *ngIf="invoice?.Customer?.PersonalNumber != ''">{{invoice?.Customer?.PersonalNumber}}</span>
        </div>        
      </mat-card>
    </div>
    <!-- / Redigerbart kundkort-->

    <div class="col-sm-12 col-md-12">      
      <p *ngIf="invoice?.Customer.Id > 0 && !invoice.Customer.Active && !invoice.SubInvoice" style="color: red;">Detta organisationsnummer används inte längre. Kontakta din kund eller SAMpoolen vid frågor.</p>
      <p *ngIf="invoice?.Customer?.PublicInfo" class="warning-box">{{ invoice?.Customer?.PublicInfo }}</p>
      <p style="color: red;" *ngIf="isAdmin">{{ invoice?.Customer?.AdminInfo }}</p>
    </div>

    <div class="col-sm-12 d-flex justify-content-start">
      <h2 class="accent">2. Specifikation</h2>
      <mat-icon class="descriptionIcon" color="accent">assignment</mat-icon>
    </div>

    <div class="col-sm-12 col-md-12">
      <mat-card>
        <mat-card-title *ngIf="!invoice.SubInvoice">Vad ska faktureras?</mat-card-title>
        <mat-card-title *ngIf="invoice.SubInvoice">Fakturerat</mat-card-title>
        <div class="row">
          <div *ngIf="invoice.SubInvoice && !IsCommissionInvoice" class="col-lg-12 paddingbottom">
            Detta är vad din kund/arbetsgivare kommer att betala för dig, inklusive skatt och sociala avgifter. Kontrollera din Ekonomi-sida för att se vad du får betalt efter sociala avgifter och kommunalskatt. Om du vill kunna begära <i>arbetsgivarintyg</i> måste du lägga till timmar och datum.
          </div>
          <div *ngIf="!invoice.SubInvoice" class="col-lg-12 paddingbottom">
            Ange vad du vill lägga till din faktura. Om du vill kunna begära <i>arbetsgivarintyg</i> måste du lägga till timmar och datum.
          </div>          
        </div>
        <div *ngIf="!invoice.SubInvoice" class="row margintop">
          <div class="col-sm-12 col-md-12">
            <mat-form-field appearance="outline" style="width:100%;">
              <mat-label>Beskrivning av utfört arbete (obligatorisk). Addera fler fakturaobjekt genom att klicka på "Lägg till" igen.</mat-label>
              <textarea matInput
                        rows="3"
                        [disabled]="!canUpdateGeneral()"
                        [(ngModel)]="invoiceQuickGroup.Description"
                        (ngModelChange)="quickRowValidate();wordCountDescription();"
                        placeholder="Beskrivning av utfört arbete"
                        id="message" name="description-quick-add"></textarea>
              <!--<mat-hint>{{resultDescription}}</mat-hint>-->
            </mat-form-field>
            <div class="col-lg-12">
              <span class="error-message">{{ErrorMessageDesc}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="!invoice.SubInvoice" class="row margintop">
          <div class="col-sm-12 col-md-3 quick-add">
            <h5>Belopp (ex moms) *</h5>            
            <input matInput [disabled]="!canUpdateGeneral() || !invoice?.Customer.Id" class="text-input amount" name="name" id="name" #name="ngModel" type="number" pattern="^-?[0-9]+(\.[0-9]{1,2})?$" [(ngModel)]="invoiceQuickGroup.Amount" ngDefaultControl [ngModelOptions]="{standalone: true}">

            <div *ngIf="name.invalid">
              <span style="color: red; font-size: 80%;">Inmatningen får bara innehålla heltal, kommatecken och max två decimaler.</span>
            </div>

            <div *ngIf="!invoice?.Customer.Id" class="select-vat">
              <mat-hint>Välj kund under punkt 1 för att kunna lägga till belopp</mat-hint>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 quick-add">
            <div class="d-flex justify-content-between">
              <h5>Moms *</h5>
              <span style="font-size: 14px;">0% moms? <a class="accent" style="font-weight: bolder;" href="https://www.sampoolen.se/invoicetool-support/" target="_blank">Klicka här</a></span>
            </div>
            <mat-select *ngIf="invoice.Customer.Country?.Id == 1 || isAdmin" [disabled]="invoiceQuickGroup.ReverseTax" placeholder="Välj moms" class="select-vat" [(ngModel)]="invoiceQuickGroup.Vat" name="rowvatselected">
              <ng-container *ngFor="let item of vatList">
                <mat-option *ngIf="invoice.Customer.Country?.Id == 1 || isAdmin" value="{{item.Value}}">{{item.Value}} %</mat-option>
              </ng-container>
            </mat-select>

            <mat-select *ngIf="invoice.Customer.Country?.Id > 1 && !isAdmin" [disabled]="invoiceQuickGroup.ReverseTax" placeholder="Välj moms" class="select-vat" [(ngModel)]="invoiceQuickGroup.VatNoSwe" name="rowvatselected">
              <ng-container *ngFor="let item of vatList">
                <mat-option *ngIf="invoice.Customer.Country?.Id > 1" value="{{item.Value}}">{{item.Value}} %</mat-option>
              </ng-container>
            </mat-select>
            <div *ngIf="!invoice?.Customer.Id" class="select-vat">
              <mat-hint>Välj kund under punkt 1 för att visa korrekt moms.</mat-hint>
            </div>
            <div class="fontsize80" style="margin-top: 5px;">
              <mat-checkbox matInput [ngModel]="invoice.ReverseTax" (change)="reverseTaxChange($event, true)">
                Omvänd skattskyldighet/byggmoms
              </mat-checkbox>
            </div>
          </div> 
          <div class="col-sm-6 col-md-3">
            <button mat-flat-button color="primary" [disabled]="!saveQuickRowValidates() || !canUpdateGeneral()" (click)="saveQuickRow(invoiceQuickGroup,true)" style="margin-top:27px;height:42px;width:100%;">
              Uppge datum/tid &nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
            </button>
          </div>
          <div class="col-sm-12 col-md-3">
            <button mat-raised-button color="accent" [disabled]="!saveQuickRowValidates() || !quickrowValid || !timeReportValidates()" (click)="saveQuickRow(invoiceQuickGroup,false); updateTotalInvoiceAmount(); updatePensionSavingsAmount()" style="margin-top:27px;height:42px;float:right;width:100%;">Spara till fakturan</button>
            <div class="error-message" style="color: black;">{{errorMessageSaveQuickRow}}</div>
          </div>
        </div>



        <div class="row time-group" [hidden]="invoice.InvoiceGroups.length == 0" *ngFor="let group of invoice?.InvoiceGroups">

          <span style="width:100%;"><b>Fakturerat</b><mat-icon *ngIf="!IsCommissionInvoice && !invoice.CreditInvoiceFor > 0" style="float:right; cursor: pointer;" (click)="editInvoiceCalendar(group)">edit</mat-icon></span>

          <span class="angular-with-newlines description" style="width:100%;margin-bottom:0px;"><i>{{group.Description}}</i></span>

          <span *ngIf="group.TimeReportStatus" class="angular-with-newlines description" style="font-size:12px;margin-bottom:20px;">Visa på faktura: {{group.TimeReportStatus?.DisplayName}}</span>

          <div class="row time-row" *ngFor="let i of group.InvoiceRows; let idx = index" style="width: 100%;">
            <div class="col">

              <span *ngIf="i.StartDate">{{i.StartDate | date: 'yyyy-MM-dd'}}</span>
              <span *ngIf="i.HoursWorked">({{i.HoursWorked}} h{{i.MinutesWorked > 0 ? ' ' + i.MinutesWorked + ' min' : ''}})</span>
              <!--<span *ngIf="group.Type.Value != 4">({{i.Vat}} % moms)</span>-->


            </div>
          </div>
          <div style="display: block; width: 100%;" *ngIf="group.ReverseTax">
            Omvänd skattskyldighet/byggmoms
          </div>
          <div class="col" style="padding-left: 0px;">
            <span *ngIf="(invoice.Customer.Country?.Name != 'Sverige') && (invoice.RecieverCountry != 'Sverige') && !isAdmin" class="row-total-amount">Belopp: {{group?.Amount | number }} {{invoice?.Currency?.Name}} (exkl. 0 % moms)</span>
            <span *ngIf="(invoice.Customer.Country?.Name == 'Sverige') || (invoice.RecieverCountry == 'Sverige') || isAdmin" class="row-total-amount">Belopp: {{group?.Amount | number }} {{invoice?.Currency?.Name}} (exkl. {{group?.Vat}} % moms)</span>
          </div>
        </div>


        <div class="margintop" style="border-top: 1px solid black;" *ngIf="!invoice?.SubInvoice">
          <h4 class="margintop">Fakturera för dina utlägg och resekostnader</h4>


          <div *ngIf="!invoice?.Customer.Id" class="select-vat">
            <mat-hint>Du måste välja en kund för att kunna lägga till utlägg och resekostnader till din faktura</mat-hint>
          </div>
          <div *ngIf="invoice.Customer.Country?.Id == 1 && invoice.Currency.Name == 'SEK'">
            <div class="row buttonrow paddingbottom">
              <div class="col-lg-6 select-vat">
                Om du vill att kunden ska betala dina utlägg och resekostnader lägger du till dem här. Du kan bara lägga till dem till fakturan innan du skickar den. Om du enbart önskar addera dem som avdrag, gör det under punkt 4. Detta kan du göra ända tills vi betalar ut din lön.
              </div>
              <div class="col-sm-6 col-md-3" *ngIf="invoice.Status.TechnicalName != 'UnderReview' || invoice.Status.Id != null || invoice.Status.TechnicalName != 'Draft'">
                <button mat-flat-button color="accent" [disabled]="!canUpdateGeneral()" (click)="editDeduction('addedToInvoice')" style="height:42px;width:100%; cursor:pointer;">
                  <mat-icon style="font-size: 16px;">add</mat-icon> Kostnader / utlägg / reseutlägg
                </button>
              </div>
              <div class="col-sm-6 col-md-3" *ngIf="invoice.Status.TechnicalName != 'UnderReview' || invoice.Status.Id != null || invoice.Status.TechnicalName != 'Draft'">
                <button mat-flat-button color="accent" [disabled]="!canUpdateGeneral()" (click)="editAllowance('addedToInvoice')" style="height:42px;width:100%; cursor: pointer;">
                  <mat-icon style="font-size: 16px;">add</mat-icon> Milersättning / traktamente
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-8" *ngIf="invoice.Customer.Country?.Id > 1 || invoice.Currency.Name != 'SEK'">
            När du fakturerar din kund i annan faktura än SEK behöver du själv räkna ut hur mycket dina utlägg är värda i valutan du valt, och lägga till dem som vanliga fakturaobjekt ovan. För att få dem tillbaka skattefritt på din löneutbetalning lägger du även till dem nedan under punkt 4.
          </div>
        </div>

        <!--Deductions added as invoice item-->
        <div [hidden]="!canUpdatePayout()">
          <div *ngFor="let c of invoice.Deductions">
            <div *ngIf="c.AddedToInvoice == true" class="time-group">
              <div style="display: inline-block; width: 100%;">
                <b>Kostnader / utlägg  </b><br />
                <div *ngIf="c.AddedToInvoice == false">
                  Ej fakturerad till kund
                </div>
                <div *ngIf="c.AddedToInvoice == true">
                  Faktureras kund
                </div>
                <i>{{c.Name}}</i><br />
                <b><span class="error-message" *ngIf="c.Attachment.Id == 0 || c.Attachment == null">Det saknas kvitto till detta utlägg</span></b>
                <div style="float:right; display: inline; cursor: pointer;">
                  <button mat-icon-button [disabled]="!canUpdateGeneral() || (c.AddedToInvoice && invoice.SubInvoice)" (click)="editDeduction('', c)" color="accent"><mat-icon>edit</mat-icon></button>
                </div>
                <div color="primary" style="float:right; display: inline; margin-right: 10px; cursor: pointer;">
                  <button mat-icon-button [disabled]="!canUpdateGeneral() || (c.AddedToInvoice && invoice.SubInvoice)" (click)="deleteDeduction(c)"><mat-icon style="font-size: 24px;">delete</mat-icon></button>
                </div>
              </div>
              <div class="d-flex justify-content-between" style="margin-top: 10px;">
                <div class="col" style="padding-left: 0px;">
                  <span class="row-total-amount">Belopp: {{c.Amount}} SEK</span>
                </div>
              </div>
              <div class="col-lg-12 warning-box" *ngIf="c.DeniedReason && c.DeniedReason.trim().length > 0">
                <span>{{c.DeniedReason}}</span>
              </div>
            </div>
          </div>
        </div>

        <!--Allowances added as invoice item-->
        <div [hidden]="!canUpdatePayout()">
          <div *ngFor="let c of invoice.Allowances">
            <div *ngIf="c.AddedToInvoice == true" class="time-group">
              <div style="display: inline-block; width: 100%;">
                <b>{{getAllowanceTypeName(c?.AllowanceType?.Id)}}</b><br />
                <div *ngIf="c.AddedToInvoice == false">
                  Ej fakturerat till kund
                </div>
                <div *ngIf="c.AddedToInvoice == true">
                  Faktureras kund
                </div>
                <div style="float:right; display: inline; cursor: pointer;">
                  <button mat-icon-button [disabled]="!canUpdateGeneral() || (c.AddedToInvoice && invoice.SubInvoice)" (click)="editAllowance(' ', c)" color="accent"><mat-icon>edit</mat-icon></button>
                </div>
                <div color="primary" style="float:right; display: inline; margin-right: 10px; cursor: pointer;">
                  <button mat-icon-button [disabled]="!canUpdateGeneral() || (c.AddedToInvoice && invoice.SubInvoice)" (click)="deleteAllowance(c)"><mat-icon>delete</mat-icon></button>
                </div>
                <div *ngIf="c.CustomReimbursementPerKm == 0">
                  {{c.Sum  | number : '1.2-2' }} SEK<br />
                </div>
                <div *ngIf="c.CustomReimbursementPerKm > 0">
                  {{c.Sum  | number : '1.2-2' }} SEK (skattefritt, {{c.ReimbursementPerKm}} SEK/km)<br />
                </div>
                {{c.TravelDateStart  | date: 'yyyy-MM-dd'}} {{c.TravelDateEnd != null ? ' - ' + (c.TravelDateEnd  | date: 'yyyy-MM-dd')  : ''}} {{ c.CarRegistrationNumber != null ? ' : ' + c.CarRegistrationNumber : '' }}
              </div>
              <div class="d-flex justify-content-between" style="margin-top: 10px;">
                <div class="col" style="padding-left: 0px;">
                  <span class="row-total-amount">Belopp: {{c.Sum  | number : '1.2-2' }} SEK</span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Fakturabilagor -->
        <div *ngIf="!invoice?.SubInvoice">
          <h4 class="margintop">Fakturabilagor</h4>
          <div class="row buttonrow paddingbottom">
            <div class="col-lg-8 select-vat" style="margin-bottom: 20px;">
              Om du vill bifoga pdf-dokument till din faktura kan du ladda upp dem här (om du vill ladda upp kvitton för utlägg gör du det här ovanför om du vill fakturera kunden för dem, eller längre ner i punkt 4 om de inte ska faktureras).
            </div>
            <div class="col-lg-12" style="margin: 10px 0;">
              <input [disabled]="isLoading ||!canUpdateGeneral()" type="file" mat-stroked-button mat-raised-button (change)="uploadDocument($event,invoice.Documents)" accept=".pdf">
            </div>
            <div class="row col-lg-12" *ngFor="let doc of invoice.Documents">
              <div *ngIf="doc?.FileType?.TechnicalName == 'AttachedInvoiceDocument'" class="row col-lg-12" style="margin-bottom: 10px;">
                <div class="col-lg-4">
                  <a color="secondary" (click)="showFile(doc)" style="cursor: pointer;">
                    <mat-icon style="position: relative; top: 7px; margin-right: 10px;">picture_as_pdf</mat-icon> {{doc?.Name}} ({{doc?.FileSize / 1048576 | number : '1.2-2'}} Mb)
                  </a>
                </div>
                <div class="col-lg-2">
                  <button mat-icon-button color="primary" [disabled]="isLoading || !canUpdateGeneral()" (click)="deleteDocumentFromList(doc,invoice.Documents)"><mat-icon style="font-size: 24px;">delete</mat-icon></button>
                </div>
              </div>
            </div>
            <div *ngIf="totalFileSize != 0" class="col-lg-12">
              <span style="font-size: 80%; color: red; font-style: italic;">{{errorMessageFiles}}</span> <br />
              Total filstorlek: {{totalFileSize / 1048576 | number : '1.2-2'}} Mb.<br />
              Antal dokument: {{ attachedFilesCount }} st.
            </div>
            <div class="col-lg-8 select-vat" style="margin-top: 20px;">
              Dina pdf-filer får vara högst 5Mb/styck och max 25 sidor långa. Totalt får du ladda upp 5 filer om totalt max 15 Mb.
            </div>
          </div>
        </div>
        <!-- / fakturabilagor -->

        <div *ngIf="invoice.Customer.Country?.Name == 'Sverige' && this.invoice.Customer.IsCompany == false" class="row" style="border-top: 1px solid black; margin: 20px 0 0 0; padding-top: 20px;">
          <div class="col-lg-12">
            <h4>Vill du anmäla RUT eller ROT? </h4>
          </div>

          <!--ROT-->
          <div style="margin-bottom: 17px;" class="col-sm-12 col-md-12 col-lg-12">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-lg-4">
                <span class="bold">ROT <mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Avdraget är max 30% för ROT">info</mat-icon></span>
              </div>
              <div class="col-lg-8">
                <mat-checkbox [disabled]="!canUpdateGeneral() || invoice.IsRut" [(ngModel)]="!invoice.IsRot" (change)="invoice.IsRot = !$event.checked; updateTotalInvoiceAmount()">Nej</mat-checkbox>
                <mat-checkbox [disabled]="!canUpdateGeneral() || invoice.IsRut" [(ngModel)]="invoice.IsRot" (change)="invoice.IsRot = $event.checked; updateTotalInvoiceAmount()" style="margin-left:15px;">Ja</mat-checkbox> &nbsp;
              </div>
            </div>
            <div *ngIf="invoice.IsRot" class="row">
              <div class="col-lg-4">
                <span>Framkörningsavgift <mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Obligatorisk vid ROT-avdrag">info</mat-icon></span>
              </div>
              <div class="col-lg-1 quick-add">
                <input matInput [disabled]="!canUpdateGeneral()" class="text-input " type="number" [(ngModel)]="invoice.RotDrivingFeeKm" (change)="invoice.RotDrivingFeeTotalAmount = (invoice.RotDrivingFeeKm * 3); updateTotalInvoiceAmount()" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-lg-1 quick-add">
                km
              </div>
              <div class="col-lg-1 quick-add">
                <input matInput disabled class="text-input " type="number" [(ngModel)]="invoice.RotDrivingFeeTotalAmount">
              </div>
              <div class="col-lg-4 quick-add">
                SEK, framkörningsavgift som faktureras din kund
              </div>
            </div>
            <div *ngIf="invoice.IsRot" class="row">
              <div class="col-lg-4">
                <span>Fastighetsbeteckning / lägenhetsnr *<mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Obligatorisk vid ROT-avdrag">info</mat-icon></span>
              </div>
              <div class="col-lg-8 quick-add">
                <input matInput [disabled]="!canUpdateGeneral()" class="text-input " type="text" [(ngModel)]="invoice.RotProperty">
              </div>
            </div>
          </div>

          <!--RUT-->
          <div style="margin-bottom: 17px;" class="col-sm-12 col-md-12 col-lg-12">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-lg-4">
                <span class="bold">RUT <mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Avdraget är max 50% för RUT.">info</mat-icon></span>
              </div>
              <div class="col-lg-8">
                <mat-checkbox [disabled]="!canUpdateGeneral() || invoice.IsRot" [(ngModel)]="!invoice.IsRut" (change)="invoice.IsRut = !$event.checked; updateTotalInvoiceAmount()">Nej</mat-checkbox>
                <mat-checkbox [disabled]="!canUpdateGeneral() || invoice.IsRot" [(ngModel)]="invoice.IsRut" (change)="invoice.IsRut = $event.checked; updateTotalInvoiceAmount()" style="margin-left:15px;">Ja</mat-checkbox> &nbsp;
              </div>
            </div>
            <div *ngIf="invoice.IsRut" class="row">
              <div class="col-lg-4">
                <span>Framkörningsavgift</span>
              </div>
              <div class="col-lg-1 quick-add">
                <input matInput [disabled]="!canUpdateGeneral()" class="text-input " type="number" [(ngModel)]="invoice.RotDrivingFeeKm" (change)="invoice.RotDrivingFeeTotalAmount = (invoice.RotDrivingFeeKm * 3); updateTotalInvoiceAmount()" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-lg-1 quick-add">
                km
              </div>
              <div class="col-lg-1 quick-add">
                <input matInput disabled class="text-input " type="number" [(ngModel)]="invoice.RotDrivingFeeTotalAmount">
              </div>
              <div class="col-lg-4 quick-add">
                framkörningsavgift som faktureras din kund
              </div>
            </div>
          </div>
        </div>

        <div class="row paddingbottom" style="border-top: 1px solid black; margin: 20px 0">
          <div class="col">
            <span></span>
            <span *ngIf="hoursWorkedTotal > 0" style="float:right;margin-top:20px;">Totalt antal timmar: {{hoursWorkedTotal}}</span>
            <span style="float:right;margin-top:20px; font-weight: bold;">
              <span class="accent">Totalt:</span> {{invoice?.TotalInvoiceAmount | number}}
              <mat-select [(ngModel)]="invoice.Currency.Id" style="width: 60px;" name="selectcurrency" (selectionChange)="currencyChanged()">
                <mat-option *ngFor="let item of currencies" value="{{item.Id}}">{{item.Name}}</mat-option>
              </mat-select>
              (ex moms)
            </span>
          </div>
        </div>

      </mat-card>
    </div>

    <div *ngIf="!invoice.SubInvoice" class="col-sm-12 d-flex justify-content-start">
      <h2 class="accent">3. Fakturering</h2>
      <mat-icon class="descriptionIcon" color="accent">description</mat-icon>
    </div>

    <div class="col-sm-12">
      <mat-card>
        <div>
          <mat-card-title *ngIf="!invoice.SubInvoice" class="mat-card-title" style="margin: 0 0 20px 0;">Hur vill du skicka fakturan? </mat-card-title>


          <div *ngIf="!invoice?.SubInvoice">
            <div class="row">
              <div style="margin-bottom: 17px;" class="col-sm-12 col-md-8 col-lg-8">
                <div class="row" style="margin-bottom: 10px;">
                  <div class="col-lg-6">
                    <span class="bold">Fakturamärkning </span> <mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Här skriver du en kort märkning av fakturan som kommer skrivas på själva fakturan. Detta kan vara ett ordernummer (s.k. PO-nummer), en kod som din kund önskar ha med på fakturan eller en egen märkning som t.ex. vilken månad fakturan gäller.">info</mat-icon>
                  </div>
                  <div class="col-lg-6">
                    <input class="invoiceinput" id="count" #myCounter='ngModel' type="text"
                           maxlength="25" [disabled]="!canUpdateGeneral()" matInput *ngIf="invoice"
                           [(ngModel)]="invoice.Marking" (ngModelChange)="wordCountMarking()" placeholder="Fakturamärkning" />
                    <mat-hint>{{resultMarking}} | 25 tecken</mat-hint>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div style="margin-bottom: 17px;" class="col-sm-12 col-md-8 col-lg-8">
                <div class="row" style="margin-bottom: -20px;">
                  <div class="col-lg-6">
                    <span class="bold" style="position: relative; top: 18px;">Betalvillkor:</span>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field>
                      <mat-select [disabled]="!canUpdateGeneral() || invoice.Customer.PaymentTermLocked" [ngModel]="invoice.PaymentTerm" (ngModelChange)="invoice.PaymentTerm = $event; getDueDate();" name="paymentterm">
                        <mat-option *ngFor="let item of paymentTerms" value="{{item}}">{{item}} dagar</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p *ngIf="canUpdateGeneral()">Beräknat förfallodatum: {{DueDate | date : 'yyyy-MM-dd'}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div style="margin-bottom: 17px;" class="col-sm-12 col-md-8 col-lg-12">
                <div class="row" style="margin-bottom: 10px;">
                  <div class="col-lg-4">
                    <span style="position: relative; top: 18px; font-weight: bold;">Skicka via:</span>
                  </div>
                  <div class="col-lg-8">
                    <mat-radio-group class="example-radio-group"
                                     [(ngModel)]="invoice.InvoiceSendType">
                      <mat-radio-button class="example-radio-button radiobuttonmargin" [disabled]="!canUpdateGeneral() || invoiceSendTypeLocked" *ngFor="let sendType of sendTypes" [value]="sendType.Id">{{sendType.Name}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="col-lg-8">
                    <mat-form-field color="accent" class="col-sm-12" style="margin-top: 15px;" *ngIf="invoice.InvoiceSendType == 1">
                      <input type="email" [disabled]="!canUpdateGeneral() || recieverEmailLocked" matInput *ngIf="invoice" [(ngModel)]="invoice.RecieverEmail" (ngModelChange)="checkCorrectEmailFormat(invoice.RecieverEmail, 'RecieverEmail')" name="email" ngModel email required placeholder="Mottagarens e-postadress" />
                    </mat-form-field>
                    <div class="col-lg-12" style="margin-top: -20px; margin-bottom: 20px;" *ngIf="invoice.InvoiceSendType == 1">
                      <span class="error-message">{{ErrorMessageRecieverEmail}}</span>
                    </div>
                    <mat-form-field color="accent" class="col-sm-12" *ngIf="invoice.InvoiceSendType == 1" style="margin-bottom:20px;">
                      <input type="email" [disabled]="!canUpdateGeneral()" matInput [(ngModel)]="invoice.RecieverEmailCopy" (ngModelChange)="checkCorrectEmailFormat(invoice.RecieverEmailCopy, 'RecieverEmailCopy')" placeholder="Fakturakopia e-postadress" />
                    </mat-form-field>
                    <div class="col-lg-12" style="margin-top: -40px; margin-bottom: 20px;" *ngIf="invoice.InvoiceSendType == 1">
                      <span class="error-message">{{ErrorMessageRecieverCopyEmail}}</span>
                    </div>
                    <mat-form-field color="accent" class="col-sm-12" *ngIf="invoice.InvoiceSendType == 1" style="margin-bottom:20px;">
                      <input type="email" [disabled]="!canUpdateGeneral()" matInput [(ngModel)]="invoice.RecieverEmailReminder" (ngModelChange)="checkCorrectEmailFormat(invoice.RecieverEmailReminder, 'RecieverEmailReminder')" placeholder="Särskild e-post för påminnelser (ej obligatorisk):" />
                    </mat-form-field>
                    <div class="col-lg-12" style="margin-top: -40px; margin-bottom: 20px;" *ngIf="invoice.InvoiceSendType == 1">
                      <span class="error-message">{{ErrorMessageRecieverReminderEmail}}</span>
                    </div>
                    <div class="row" style="margin-top: 15px;" *ngIf="invoice.InvoiceSendType == 3">
                      <div class="col-lg-12" style="margin-bottom: 10px;">
                        <span *ngIf="invoice.Customer.GlnNumber">
                          Ditt angivna GLN-nummer för denna kund är: <span style="font-weight: bolder;" class="accent">{{ invoice.Customer.GlnNumber }}</span>.
                        </span>
                        <span *ngIf="!invoice.Customer.GlnNumber">
                          Om din kund kräver GLN-nummer, lägg till det här (ej obligatoriskt):
                        </span>
                        <div class="row">
                          <div class="col-lg-4">
                            <input class="invoiceinput" type="text" matInput [(ngModel)]="invoice.Customer.GlnNumber" placeholder="GLN-nummer" />
                          </div>
                          <div class="col-lg-4">
                            <button mat-stroked-button (click)="updateCustomerGlnNumber(invoice.Customer.GlnNumber, invoice.Customer.Id)">Spara GLN-nummer</button>
                          </div>
                        </div>
                      </div>
                      <mat-form-field color="accent" class="col-sm-12" *ngIf="!invoice.Customer.GlnNumber" style="margin-bottom:20px;">
                        <input type="email" [disabled]="!canUpdateGeneral()" matInput [(ngModel)]="invoice.RecieverEmailCopy" placeholder="Fakturakopia e-postadress" />
                      </mat-form-field>
                      <!--If sent as e-faktura, reminder address is mandatory-->
                      <mat-form-field color="accent" class="col-sm-12" *ngIf="invoice.InvoiceSendType == 3" style="margin-bottom:20px;">
                        <input type="email" required [disabled]="!canUpdateGeneral()" matInput [(ngModel)]="invoice.RecieverEmailReminder" (ngModelChange)="checkCorrectEmailFormat(invoice.RecieverEmailReminder, 'RecieverEmailReminder')" placeholder="E-post för eventuella påminnelser:" />
                      </mat-form-field>
                      <div class="col-lg-12" *ngIf="invoice.InvoiceSendType == 3">
                        <!--<span class="error-message">{{ErrorMessageRecieverReminderEmail}}</span>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="invoice.InvoiceSendType == 3" class="row" style="margin-bottom: 10px;">
                  <div class="col-lg-4">
                    <span class="bold">Elektronisk referens </span> <mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Om din kund kräver en elektronisk referens för att kunna hantera e-fakturan skriver du den här">info</mat-icon>
                  </div>
                  <div class="col-lg-6">
                    <input class="invoiceinput" type="text" [disabled]="!canUpdateGeneral()" matInput [(ngModel)]="invoice.ReferenceText" placeholder="Unik fakturareferens" />
                    <span *ngIf="electronicReferenceMandatory" style="font-size: 80%; font-style: italic;">Din kund kräver elektronisk referens på e-fakturor. Oftast är detta en sifferkod, men kan variera. Om du inte fått info från din kund behöver du fråga din kund efter elektronisk referens på e-fakturor.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--<div style="margin-left: 20px; margin-bottom:40px;" *ngIf="invoice.InvoiceSendType == 2">

          <div *ngIf="invoice?.Customer.Id > 0">
            <p>
              Angiven mottagaradress:
            </p>

            <span class="bold">{{invoice.RecieverCompanyName}}</span><br />
            <span>Att: {{invoice.RecieverAtt}}</span><br />
            <span>{{invoice.RecieverAddress1}}</span> <span>{{invoice.RecieverAddress2}}</span><br />
            <span>{{invoice.RecieverZip}} {{invoice.RecieverCity}}</span><br /><br />

            <p>
              <span style="font-style: italic; margin-top: 15px;">
                Om adressen inte stämmer, redigera din kund:
                <anms-big-input-action icon="edit" color="accent"
                                       (action)="editCustomer()"
                                       [disabled]="!canUpdateGeneral()"
                                       matTooltip="Redigera" matTooltipPosition="before">
                </anms-big-input-action>
              </span>
            </p>
          </div>
        </div>-->

        <div *ngIf="!invoice?.SubInvoice" class="row">
          <div style="margin-bottom: 17px;" class="col-sm-12 col-md-8 col-lg-8">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-lg-6">
                <span class="bold">Fakturaavgiften ({{invoice.InvoiceFee}} kr) betalas av: </span>
              </div>
              <div class="col-lg-6">
                <mat-checkbox [disabled]="!canUpdateGeneral() || invoiceFeeLocked" [(ngModel)]="invoice.FeePaidByReciever" (change)="invoice.FeePaidByReciever = $event.checked;">Kunden</mat-checkbox>
                <mat-checkbox [disabled]="!canUpdateGeneral() || invoiceFeeLocked" [(ngModel)]="!invoice.FeePaidByReciever" (change)="invoice.FeePaidByReciever = !$event.checked;" style="margin-left:15px;">Mig</mat-checkbox> &nbsp; <i style="font-size: 13px;">(ca 15 kr netto)</i>
                <div *ngIf="!isAdmin && invoice.Customer.AcceptsInvoiceFeeLocked && !invoice.Customer.AcceptsInvoiceFee" style="font-size: 80%; font-style: italic;">
                  Denna kund godkänner inte att du fakturerar dem för fakturaavgiften
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!hasSubInvoices">
          <div style="margin-bottom: 17px;" class="col-sm-12 col-md-8 col-lg-8">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-lg-6">
                <span class="bold">Expressutbetalning? <mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Utbetalas direkt efter bekräftat underlag, kostnad är 5 % av fakturabeloppet.">info</mat-icon></span>
              </div>
              <div class="col-lg-6" [hidden]="invoice.Customer.Country != null && invoice.Customer.Country.Name != 'Sverige'">
                <mat-radio-button class="ml-2 radiobuttonmargin" [checked]="!invoice.ExpressPayOut" #rbExpressPayoutNo [value]="false" (click)="expressPayoutClick(false)" id="rbExpressPayoutNo">Nej</mat-radio-button>
                <mat-radio-button class="ml-2 radiobuttonmargin" [checked]="invoice.ExpressPayOut" #rbExpressPayoutYes [value]="true" (click)="expressPayoutClick(true)" id="rbExpressPayoutYes">Ja</mat-radio-button>
              </div>
              <div class="col-lg-6" *ngIf="invoice.Customer.Country?.Name != 'Sverige'">
                Expressutbetalning är bara möjlig när du valt en kund i Sverige.
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-lg-9">
      <div class="col-lg-9">
        <div *ngIf="pensionSavingsTotalAmount > 0">
          <mat-form-field style="margin-right: 10px; width: 252px;">
            <input class="text-input" matInput disabled placeholder="Total tjänstepension inkl skatt för denna faktura" [(value)]="pensionSavingsTotalAmount">
          </mat-form-field>
          <div>Du har angett att du vill avsätta {{pensionSavingsTotalAmount}} SEK (inkl skatt) till din tjänstepension. <span class="error-message" *ngIf="errorMessagePension != ''">{{errorMessagePension + '.'}}</span> Under punkt 4 kan du ändra för denna faktura.</div>
        </div>
      </div>
    </div>

    <!--<mat-form-field *ngIf="!invoice.SubInvoice" class="col-3">
      <input type="text" [(ngModel)]="DiscountCode" matInput placeholder="Kampanjkod" disabled />
    </mat-form-field>-->
    <div class="col-lg-6"></div>
    <div class="col-lg-6 d-flex justify-content-end" style="margin-bottom:20px;">
      <mat-checkbox label="Härmed godkänner jag" *ngIf="invoice && !invoice.SubInvoice" class="col-lg-6 col-sm-6 mb-2" [(ngModel)]="invoice.termsAgreed">
        Jag godkänner <a href="https://api.sampoolen.se/Documents/GeneralTermsOfUser.pdf" target="_blank" class="mr-2">användaravtalet</a><br /> och de <a href="https://api.sampoolen.se/Documents/GeneralTermsRegistration.pdf" class="mr-2" target="_blank">generella villkoren</a>
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <button mat-flat-button color="primary" class="col-sm-6 col-lg-4" (click)="cancel()">Avbryt</button>
    </div>
    <div class="col-sm-6 buttonrow">
      <button *ngIf="!invoice.SubInvoice" mat-raised-button (click)="send(invoice)" [disabled]="!sendValidates() || !canUpdateInvoice() || !sendInvoiceValidates" color="accent">Skicka faktura</button>
      <!--<button mat-stroked-button (click)="preview(invoice)" [disabled]="isLoading || !validPreview()">Sammanställning</button>-->
      <button mat-stroked-button *ngIf="invoice.Status.TechnicalName != 'Draft'" (click)="saveDraft(invoice)">Spara</button>
      <button mat-stroked-button *ngIf="invoice.Status.TechnicalName == 'Draft'" (click)="saveDraft(invoice)" [disabled]="!canUpdateInvoiceDraft()">Spara utkast</button>
    </div>
    <div class="col-lg-12" style=" text-align: right;">
      <span *ngIf="!invoice.SubInvoice" style="font-size: 80%; color: red; font-style: italic;">{{ErrorMessageSend}} </span><br />
      <span *ngIf="errorMessagePensionSavingsCompany != ''" style="font-size: 80%; color: red; font-style: italic;">{{errorMessagePensionSavingsCompany}} </span><br />
      <span *ngIf="invoice?.CreditInvoiceFor != null" style="font-size: 80%; color: red; font-style: italic;">Observera att detta är en <b>kreditfaktura</b>. Den kommer att kvitta din ordinarie faktura. </span>
    </div>
  </div>

  <div *ngIf="!isLoading && invoice.CreditInvoiceFor == null" class="row align-items-end" style="background-color: #f3f3f3; margin-top: 30px; margin-bottom: 30px;  padding-top: 20px; padding-bottom: 30px;">
    <div class="col-sm-12">
      <h2 class="accent">4. Uppgifter för din utbetalning<mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Om din kund ska ersätta dig för dina utgifter måste du addera detta till fakturan under punkt 2!">info</mat-icon></h2>
    </div>
    <!-- 4 Utbetalning -->
    <div class="col-sm-12 col-md-12">
      <mat-card class="col-sm-12" *ngIf="!hasSubInvoices">
        <div class="row paddingbottom">
          <div class="col-lg-8">
            <mat-card-title>Kostnader / utlägg / reseutlägg </mat-card-title>
            <p>
              Har du gjort inköp eller haft kostnader i samband med ditt jobb kan du få tillbaka den kostnaden skattefritt.
              Uppge belopp samt ladda upp underlag (kvitton, faktura osv) här. Även biljetter för resor lägger du till här.
            </p>
            <button mat-flat-button color="accent" (click)="editDeduction('')" style="height:42px;">
              <mat-icon>add</mat-icon> Kostnader / utlägg / reseutlägg
            </button>
          </div>
          <div class="col-lg-4 col-md-3">
          </div>
          <div class="col-lg-12">
            <!-- Added deductions -->
            <div>
              <div *ngFor="let c of invoice.Deductions" class="time-group">
                <div style="display: inline-block; width: 100%;">
                  <span><b>Kostnader / utlägg / reseutlägg</b></span>
                  <span style="float: right;">
                    <span *ngIf="c.DeniedByAdmin && !c.ApprovedByAdmin" style="color: red;">Ej godkänt</span>
                    <span *ngIf="!c.DeniedByAdmin && c.ApprovedByAdmin" style="color: green;">Godkänt</span>
                    <span *ngIf="!c.DeniedByAdmin && !c.ApprovedByAdmin" style="color: black;">Granskas</span>
                  </span>
                  <br />
                  <div *ngIf="c.AddedToInvoice == false && !invoice.SubInvoice">
                    Ej fakturerat till kund
                  </div>
                  <div *ngIf="c.AddedToInvoice == true && !invoice.SubInvoice">
                    Fakturerat till kund
                  </div>
                  <i>{{c.Name}}</i><br />
                  <b><span class="error-message" *ngIf="c.Attachment.Id == 0 || c.Attachment == null">Det saknas kvitto till detta utlägg</span></b>

                  <div [hidden]="!isAdmin && ((c.AddedToInvoice && !canUpdateGeneral()) || (c.AddedToInvoice && invoice.SubInvoice))" style="float:right; display: inline; cursor: pointer;" (click)="editDeduction('', c)"><mat-icon style="font-size: 24px;">edit</mat-icon></div>
                  <div [hidden]="!isAdmin && ((c.AddedToInvoice && !canUpdateGeneral()) || (c.AddedToInvoice && invoice.SubInvoice))" color="primary" style="float:right; display: inline; margin-right: 10px; cursor: pointer;" (click)="deleteDeduction(c)"><mat-icon style="font-size: 24px;">delete</mat-icon></div>
                </div>
                <div class="d-flex justify-content-between" style="margin-top: 10px;">
                  <div class="col" style="padding-left: 0px;">
                    <span class="row-total-amount">Belopp: {{c.Amount}} SEK</span>
                  </div>
                </div>
                <div class="col-lg-12 warning-box" *ngIf="c.DeniedReason && c.DeniedReason.trim().length > 0">
                  <span>{{c.DeniedReason}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="col-sm-12" *ngIf="!hasSubInvoices">
        <div class="row paddingbottom">
          <div class="col-lg-8">
            <mat-card-title>Traktamente och milersättning </mat-card-title>
            <p>
              Har du rätt till traktamenten eller milersättning när du jobbat på annan ort eller utomlands så lägger du in uppgifter för dina resor här.
            </p>
            <button mat-flat-button color="accent" (click)="editAllowance('notAdded')" style="height:42px;">
              <mat-icon>add</mat-icon> Milersättning / traktamente
            </button>
          </div>
          <div class="col-lg-4 col-md-3">
          </div>
          <div class="col-lg-12">
            <!-- Added allowances -->
            <div [hidden]="!canUpdatePayout()">
              <div *ngFor="let c of invoice.Allowances" class="time-group">
                <div style="display: inline-block; width: 100%;">
                  <b>{{getAllowanceTypeName(c?.AllowanceType?.Id)}}</b><br />
                  <div *ngIf="c.AddedToInvoice == false">
                    Ej fakturerat till kund
                  </div>
                  <div *ngIf="c.AddedToInvoice == true">
                    Fakturerat till kund
                  </div>
                  <div [hidden]="(c.AddedToInvoice == true && !canUpdateGeneral()) || (c.AddedToInvoice && invoice.SubInvoice)" style="float:right; display: inline; cursor: pointer;" (click)="editAllowance('', c)"><mat-icon>edit</mat-icon></div>
                  <div [hidden]="(c.AddedToInvoice == true && !canUpdateGeneral()) || (c.AddedToInvoice && invoice.SubInvoice)" color="primary" style="float:right; display: inline; margin-right: 10px; cursor: pointer;" (click)="deleteAllowance(c)"><mat-icon>delete</mat-icon></div>
                  {{c.Sum  | number : '1.2-2' }} SEK <br />
                  <span *ngIf="c.Mileage > 0">{{c.Mileage}} km <br /></span>
                  {{c.TravelDateStart  | date: 'yyyy-MM-dd'}} {{c.TravelDateEnd != null ? ' - ' + (c.TravelDateEnd  | date: 'yyyy-MM-dd')  : ''}} {{ c.CarRegistrationNumber != null ? ' : ' + c.CarRegistrationNumber : '' }}
                </div>
                <div class="d-flex justify-content-between" style="margin-top: 10px;">
                  <div class="col" style="padding-left: 0px;">
                    <span class="row-total-amount">Belopp: {{c.Sum  | number : '1.2-2' }} SEK</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="col-sm-12">

        <div class="row paddingbottom">
          <div class="col-lg-9">
            <mat-card-title>Avsättning till tjänstepension</mat-card-title>
            <div *ngIf="invoiceSender?.PensionSavingsSettings == null || pensionSavingsCompanyId == 3" style="margin-bottom: 15px;">Du har inget pensionsbolag angivet i dina inställningar. Du behöver välja ett för att vi ska kunna betala in din pensionsavsättning.</div>

            <div class="col-lg-6">
              <div>Val av tjänstepensionsföretag:</div>
              <mat-form-field style="width: 100%;">
                <select [ngModel]="invoiceSender?.PensionSavingsSettings?.PensionSavingsCompany?.Id"
                        (ngModelChange)="pensionSavingsCompanyChanged($event); updatePensionSavingsCompany($event)"
                        matNativeControl
                        [ngModelOptions]="{standalone: true}">
                  <option value="1">SPP</option>
                  <option value="2">Avanza</option>
                  <option value="3">Inget valt</option>
                </select>
              </mat-form-field>
            </div>
            <p>
              Du kan göra avsättningar till din tjänstepension. I dina inställningar kan du förinställa ett belopp som du sedan kan ändra här om du vill.
            </p>
            <p *ngIf="invoice.Currency.Name != 'SEK' && pensionSavingsPercent == 0" style="font-style: italic;">
              Observera att du har skapat fakturan i utländsk valuta, men du anger önskad pensionsavsättning i SEK.
            </p>

            <p *ngIf="invoice.Currency.Name != 'SEK' && pensionSavingsPercent > 0" style="font-style: italic;">
              Observera att du har skapat fakturan i utländsk valuta, och att du här nedan ser den preliminära avsättningen i SEK. När du valt en procentuell avsättning räknas det från din slutliga utbetalning i SEK, som beror på växelkursen.
            </p>
          </div>
          <div class="col-lg-3">
          </div>
          <div class="col-lg-12">
            <div class="payment-part-body">
              <div *ngIf="pensionSavingsFactorPreset > 0">
                <mat-form-field style="margin-right: 10px;">
                  <input class="text-input" matInput disabled placeholder="Förinställd procentandel" [(ngModel)]="pensionSavingsFactorPreset">
                </mat-form-field><span> %</span>
              </div>

              <div *ngIf="pensionSavingsAmountPreset > 0">
                <mat-form-field style="margin-right: 10px;">
                  <input class="text-input" matInput disabled type="number" placeholder="Förinställt fast belopp" [(ngModel)]="pensionSavingsAmountPreset">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="payment-part-body">
              <mat-form-field style="margin-right: 10px;">
                <input class="text-input" matInput [disabled]="!canUpdatePayout() || hasSubInvoices" type="number" placeholder="Avsättning i % av fakturabeloppet" [(ngModel)]="pensionSavingsPercent" (ngModelChange)="updatePensionSavingsAmount('Share')">
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="payment-part-body">
              <mat-form-field style="margin-right: 10px;">
                <input class="text-input" matInput [disabled]="!canUpdatePayout() || hasSubInvoices" type="number" placeholder="Tjänstepension exkl skatt" [(ngModel)]="pensionSavingsAmount" (ngModelChange)="updatePensionSavingsAmount('Amount')">
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="payment-part-body">
              <mat-form-field style="margin-right: 10px;">
                <input class="text-input" matInput disabled placeholder="Total tjänstepension inkl skatt" [(value)]="pensionSavingsTotalAmount">
              </mat-form-field>
            </div>
            <span class="error-message">{{errorMessagePension}}</span>
          </div>
          <div class="col-lg-9">
            <p>
              På den summa du sätter undan tillkommer en tjänstepensionsskatt på 24,26 %. Den totala summan av avsättningen och skatten dras från det belopp vi beräknar din lön på.
              <br /><br />
              Löneväxling passar inte alla. Din allmänna pension minskar om din bruttolön per månad efter löneväxling blir lägre än 51 250 kr (2024).
              Om din lön blir mindre än så kan även din sjukpenning, föräldrapenning och andra socialförsäkringsförmåner påverkas.
            </p>
          </div>
          <div class="col-lg-3">
          </div>
        </div>
      </mat-card>

      <mat-card *ngIf="!invoice.SubInvoice" class="col-sm-12">

        <div class="row paddingbottom">
          <div class="col-lg-9">
            <mat-card-title>Delning av lön</mat-card-title>
            <p>
              Du kan dela med dig av löneutbetalning om du jobbat med någon eller några andra.<br />
              Fyll i personnummer och andel som ska delas ut här. Ange procentandel eller hur kronor av fakturabeloppet du vill dela med dig av, beroende på vald delningstyp. Tänk på att arbetsgivaravgift och skatt dras även från din medarbetares lönesumma.
            </p>
            <button *ngIf="!hasSubInvoices" mat-raised-button color="accent" [disabled]="!canUpdatePayout()" (click)="addSalaryShare()" style="height:42px;">
              <mat-icon>add</mat-icon> Lägg till delning av lön
            </button>

          </div>
          <div class="col-lg-3">
          </div>
          <div class="col-lg-12">
            <div class="payment-part-body">
              <div *ngFor="let c of invoice.SalaryShares; let i = index" class="payment-part-row col-lg-9">

                <div class="row" *ngIf="c.SalaryReciever.Guid == null">
                  <h4 style="width: 100%; margin-left: 15px;">Mottagarens personnr/orgnr</h4>
                  <div class="col-lg-3 quick-add">
                    <input matInput class="text-input amount search" [(ngModel)]="searchAccountValue" (ngModelChange)="searchAccountValue = $event;personalOrCompanyNumberValid($event);" (input)="SearchAccount(searchAccountValue,c)" type="text" placeholder="ÅÅMMDDNNNN">
                    <mat-icon [hidden]="!personalOrOrgNumberValid || searchAccountValue == null" class="check" matTooltipPosition="after">check</mat-icon>

                    <div *ngIf="personalOrOrgNumberValid && c.SalaryReciever.Guid == null" style="height: 20px;">
                      <span class="fontsize80" style="font-style: italic;">Användaren finns inte registrerad</span>
                    </div>
                  </div>

                  <div *ngIf="personalOrOrgNumberValid && c.SalaryReciever.Guid == null" class="col-lg-6 quick-add">
                    <button mat-flat-button color="accent" [disabled]="!canUpdateGeneral()" (click)="openAddUserAccountModal(c)" style="height:43px; width:250px; cursor:pointer;">
                      <mat-icon>add</mat-icon> Lägg till mottagare
                    </button>
                    <div class="fontsize80" style="height: 20px; font-style: italic; padding-top: 3px;">Mottagaren saknas i vårt register, klicka för att lägga till</div>
                  </div>

                  <div class="col-lg-3">
                    <button mat-raised-button color="primary" style="margin-top: 5px;" [disabled]="!canUpdatePayout() || hasSubInvoices" (click)="deleteShare(i)">Ta bort</button>
                  </div>
                </div>

                <div class="row" *ngIf="c.SalaryReciever.FirstName != null">
                  <div class="col-lg-3">
                    <div style="height: 20px; font-weight: bold;"><span>{{c?.SalaryReciever?.FirstName + ' ' + c?.SalaryReciever?.LastName}}</span></div>
                  </div>

                  <div class="col-lg-3">
                    <mat-form-field style="margin-right: 10px;">
                      <mat-select placeholder="Ange typ" [disabled]="!canUpdatePayout() || hasSubInvoices" [(ngModel)]="c.ShareType.Id" (ngModelChange)="shareTypeChanged($event,c)">
                        <mat-option *ngFor="let e of invoiceShareTypes" [value]="e.Id">
                          {{ e.Name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-3">
                    <mat-form-field *ngIf="c.ShareType.Id == 2" style="margin-right: 10px;">
                      <input class="text-input" matInput [disabled]="!canUpdatePayout() || hasSubInvoices" type="number" placeholder="Del av fakturabeloppet (SEK)" [(ngModel)]="c.Salary" (ngModelChange)="c.Salary = $event">
                    </mat-form-field>

                    <mat-form-field *ngIf="c.ShareType.Id == 1" style="margin-right: 10px;">
                      <input class="text-input" matInput [disabled]="!canUpdatePayout() || hasSubInvoices" type="number" placeholder="Andel (%)" [(ngModel)]="c.Share" (ngModelChange)="c.Share = $event">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <button mat-raised-button color="primary" style="margin-top: 5px;" [disabled]="!canUpdatePayout() || hasSubInvoices" (click)="deleteShare(i)">Ta bort</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="col-sm-12 pt-3 mt-5" style="margin-top:15px;">
      <mat-card class="col-sm-12">

        <mat-card-title style="margin-bottom:3px;">
          Meddelande till SAMpoolen 
        </mat-card-title>

        <div style="margin-bottom:20px;">
          <mat-form-field color="accent" appearance="outline" class="col-sm-12 col-lg-10">
            <mat-label>Meddelande</mat-label>
            <textarea matInput [ngModel]="invoice?.Message" (ngModelChange)="invoice.Message = $event" rows="3" placeholder="Skriv meddelande här" id="message" name="message" md-select-on-focus></textarea>
          </mat-form-field>
          <button mat-stroked-button mat-raised-button class="col-sm-12 col-lg-2" *ngIf="(!canUpdateInvoiceDraft() && invoice.Id != 0) || invoice.Status.Name != 'Utkast'" [disabled]="invoice.Message == undefined || invoice.Message == ''" (click)="sendMessage(invoice.Message)">Skicka</button>
        </div> 

        <div *ngFor="let message of invoice.Messages">
          <span>
            <i>({{message.Created | date: 'yyyy-MM-dd'}})</i>&nbsp;<b>{{message.CreatedBy}}:</b><br />
            <span class="angular-with-newlines">{{message.Text}}</span>
          </span>
        </div>
      </mat-card>
    </div>

    <div class="col-lg-9">
    </div>
    <mat-form-field *ngIf="!invoice.SubInvoice" class="col-3">
      <input type="text" [(ngModel)]="DiscountCode" matInput placeholder="Kampanjkod" disabled />
    </mat-form-field>
    <div class="col-lg-6"></div>
    <div class="col-lg-6 d-flex justify-content-end" style="margin-bottom:20px;">
      <mat-checkbox label="Härmed godkänner jag" *ngIf="invoice && !invoice.SubInvoice" class="col-lg-6 col-sm-6 mb-2" [(ngModel)]="invoice.termsAgreed">
        Jag godkänner <a href="https://api.sampoolen.se/Documents/GeneralTermsOfUser.pdf" target="_blank" class="mr-2">användaravtalet</a><br /> och de <a href="https://api.sampoolen.se/Documents/GeneralTermsRegistration.pdf" target="_blank" class="mr-2">generella villkoren</a>
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <button mat-flat-button color="primary" class="col-sm-6 col-lg-4" (click)="cancel()">Avbryt</button>
    </div>
    <div class="col-sm-6 buttonrow">
      <button *ngIf="!invoice.SubInvoice" mat-raised-button (click)="send(invoice)" [disabled]="isLoading || !sendValidates() || !canUpdateInvoice()" color="accent">Skicka faktura</button>
      <!--<button mat-stroked-button (click)="preview(invoice)" [disabled]="isLoading || !validPreview()">Sammanställning</button>-->
      <button mat-stroked-button *ngIf="invoice.Status.TechnicalName != 'Draft'" (click)="saveDraft(invoice)">Spara</button>
      <button mat-stroked-button *ngIf="invoice.Status.TechnicalName == 'Draft'" (click)="saveDraft(invoice)" [disabled]="!canUpdateInvoiceDraft()">Spara utkast</button>
    </div>
    <div class="col-lg-12" style=" text-align: right;">
      <span *ngIf="!invoice.SubInvoice" style="font-size: 80%; color: red; font-style: italic;">{{ErrorMessageSend}} </span><br />
      <span *ngIf="errorMessagePensionSavingsCompany != ''" style="font-size: 80%; color: red; font-style: italic;">{{errorMessagePensionSavingsCompany}} </span><br />
    </div>
  </div>
</div>
