const packageJson = require('../../package.json');

export const environment = {
  appId: 2,
  appName: 'Samfinans',
  envName: 'TEST Samfinans',
  themeName: 'samfinans-theme',
  hostBase: "https://app-dev.samekonomi.se/",
  hostApi: "https://api-dev.samconsulting.se/api/",
  hostAuth: "https://api-dev.samconsulting.se/oauth2/token",
  bankIdUrl: "https://login.sampoolen.se/Account/Login?caller=4&appId=2",
    production: true,
    versions: {
        app: packageJson.version,
        angular: packageJson.dependencies['@angular/core'],
        ngrx: packageJson.dependencies['@ngrx/store'],
        material: packageJson.dependencies['@angular/material'],
        bootstrap: packageJson.dependencies.bootstrap,
        rxjs: packageJson.dependencies.rxjs,
        angularCli: packageJson.devDependencies['@angular/cli'],
        typescript: packageJson.devDependencies['typescript']
    }
};
