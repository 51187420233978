<h1 mat-dialog-title *ngIf="isNewCustomer">Nytt företag</h1>
<h2 mat-dialog-title *ngIf="!isNewCustomer" matTooltip="{{customer?.Id}}">{{customer.Name}}</h2>

<button mat-icon-button (click)="onClose()" style="position:absolute; top:0; right:0;">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content class="modal-content">
  <form #personForm="ngForm" novalidate>

    <!-- Searchbox only shown to users adding a new business customer -->
    <div *ngIf="isNewCustomer">
      <div style="margin: 20px 0;">
        <mat-form-field style="width: 100%;">
          <mat-label>Organisationsnummer / VAT</mat-label>
          <input matInput [(ngModel)]="searchAccountValue" (ngModelChange)="orgNumberValidCheck($event)" name="searchCompany" id="searchCompany" type="text" placeholder="NNNNNN-NNNN">
          <mat-icon *ngIf="orgNumberValid" color="accent" matSuffix matTooltipPosition="before" matTooltip="Företaget kontrollerat hos Hitta.se" style="color: green;">check</mat-icon>
          <mat-icon *ngIf="!orgNumberValid" color="accent" matSuffix matTooltipPosition="before" matTooltip="Företaget kan ej verifieras" style="color: red;">close</mat-icon>
        </mat-form-field>
        <span class="errormessage">{{errorMessageOrgnr}}</span>
      </div>
    </div>

    <!-- Regular text field shown to admin, and disabled for users when a customer is once saved -->
    <div *ngIf="!isNewCustomer">
      <mat-form-field style="width: 100%;">
        <mat-label>Organisationsnummer / VAT</mat-label>
        <input matInput [disabled]="true" type="text" [(ngModel)]='customer.OrgNumber' placeholder="NNNNNN-NNNN (10 siffror och bindestreck)" required name="OrgNumber">
      </mat-form-field>
    </div>

    <mat-form-field *ngIf="!isNewCustomer" style="width: 100%;">
      <mat-select [disabled]="true" name="country" placeholder="Land" [(ngModel)]="customer?.Country.Id" (ngModelChange)="customer.Country.Id = $event; getCountryName(customer.Country.Id); orgNumberValidCheck(searchAccountValue)" [ngModelOptions]="{ standalone: true }" required>
        <mat-option *ngFor="let c of countries" [value]="c.Id">
          {{ c.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Other form fields are shown when orgnr is added, or if private person customer. Always shown to admin -->
    <mat-form-field *ngIf="orgNumberValid || !isNewCustomer" style="width: 100%;">
      <input matInput type="text" [(ngModel)]='customer.Name' placeholder="Namn" required name="name">
    </mat-form-field>

    <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewCustomer" style="width: 100%;">
      <input matInput type="text" [(ngModel)]='customer.Address' placeholder="Adress" required name="Address">
    </mat-form-field>

    <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewCustomer" style="width: 100%;">
      <input matInput type="text" [(ngModel)]='customer.Zip' placeholder="Postnummer" required name="Zip">
    </mat-form-field>

    <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewCustomer" style="width: 100%;">
      <input matInput type="text" [(ngModel)]='customer.City' placeholder="Ort" required name="City">
    </mat-form-field>

    <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewCustomer" style="width: 100%;">
      <input matInput type="text" [disabled]="customer.CoLocked && !isAdmin" [(ngModel)]='customer.Co' placeholder="C/o" name="Co">
    </mat-form-field>

    <mat-form-field *ngIf="pNrValid || orgNumberValid || !isNewCustomer" style="width: 100%;">
      <input matInput type="text" [disabled]="customer.PhoneLocked && !isAdmin" (ngModelChange)="phoneNumberValidCheck($event)" [(ngModel)]='customer.Phone' placeholder="Telefon" name="Phone">
    </mat-form-field>
    <span class="errormessage">{{errorMessagePhoneNr}}</span>

    <mat-form-field *ngIf="appId == 1 && (pNrValid || orgNumberValid || !isNewCustomer)" style="width: 100%; margin-bottom:30px;">
      <input matInput type="email" [disabled]="customer.InvoiceEmailLocked && !isAdmin" [(ngModel)]='customer.InvoiceEmail' placeholder="Epost för faktura" name="InvoiceEmail">
      <!--<mat-icon *ngIf="!correctEmail.valid && !customer.InvoiceEmailLocked" matSuffix color="accent">warning</mat-icon>-->
    </mat-form-field>

    <mat-form-field *ngIf="appId == 2 && (pNrValid || orgNumberValid || !isNewCustomer)" style="width: 100%; margin-bottom:30px;">
      <input matInput type="email" [(ngModel)]='customer.Email' placeholder="Epost" name="Email">
    </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions class="modal-footer">
  <div>{{errorMessage}}</div>
  <button mat-raised-button color="accent" [disabled]="!formValid() || !orgNumberValid" (click)="save(customer)">
    <mat-icon>check</mat-icon>
    &nbsp;Spara&nbsp;
  </button>&nbsp;
</mat-dialog-actions>
