import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../services/AuthenticationService';
import { IContact } from '../interfaces/IContact';
import { throwError } from 'rxjs';
import { IAccountSettings } from '../interfaces/user/IAccountSettings';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable()
export class OrganizationService {
    public apiAuthUrl: string;
    private apiBaseUrl: string;
    public token: string;
    private userString: string;
    private httpOptions: any;
    private handleError: HandleError;
    
    constructor(
        private http: HttpClient,
      private router: Router,
      public snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        httpErrorHandler: HttpErrorHandler,
        private route: ActivatedRoute)
    {
        this.apiBaseUrl = env.hostApi;
        this.handleError = httpErrorHandler.createHandleError('OrganizationService');
        this.setHttpOptions();
    }
    
    getAll(): Observable<any> {
        
        var url = this.apiBaseUrl + 'Organizations';
        
        return this.http.get<any>(url, this.httpOptions)
            .pipe(
              tap((response: any) => this.onFinished(response)),
            catchError(
                (err: any) => {
                    return new Observable<any>();
                }
            ));    
    }

    get(id: number): Observable<any> {

          var url = this.apiBaseUrl + 'Organizations/' + id.toString();

          return this.http.get<any>(url, this.httpOptions)
              .pipe(
                tap((response: any) => this.onFinished(response)),
              catchError(
                  (err: any) => {
                      return new Observable<any>();
                  }
              ));
  }

  getInvoiceById(id: number, invoiceId: number): Observable<any> {

    var url = this.apiBaseUrl + 'Organizations/' + id.toString() + '/Invoices/' + invoiceId.toString();

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  

  getRoles(id: number): Observable<any> {

    var url = this.apiBaseUrl + 'Organizations/' + id.toString() + '/Roles';

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  create(companyId: number): Observable<any> {
      var url = this.apiBaseUrl + 'Organizations';
      var payload = { CompanyId: companyId };

      return this.http.post<any>(url, payload, this.httpOptions)
        .pipe(
          tap((response: any) => this.onFinished(response)),
          catchError(
            (err: any) => {
              return throwError(err);
            }
          )
        );
  }

  createUser(organizationId: number, user: IAccountSettings): Observable<any> {
    var url = this.apiBaseUrl + 'Organizations/' + organizationId + '/users';
    
    var payload = {
      "Email": user.Email,
      "FirstName": user.FirstName,
      "LastName": user.LastName,
      "PersonalNumber": user.PersonalNumber,
      "RoleIds": user.RoleIds
    }

    return this.http.post<any>(url, payload, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            return throwError(err);
          }
        )
      );
  }

  updateUser(organizationId: number, user: IAccountSettings): Observable<any> {
    var url = this.apiBaseUrl + 'Organizations/' + organizationId + '/users/' + user.Guid;
    
    var payload = {
      "Email": user.Email,
      "RoleIds": user.RoleIds,
      "Suspended": user.Suspended
    }

    return this.http.put<any>(url, payload, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            return throwError(err);
          }
        )
      );
    }

  createInvoice(invoice: any, organizationId: number, message: string, redirect: string): Observable<any> {
    var c = JSON.stringify(invoice);
    var url = this.apiBaseUrl + 'Organizations/' + organizationId.toString() + '/Invoices';

    return this.http.post<any>(url, c, this.httpOptions)
      .pipe(
        tap((response: any) => this.onInvoiceSaved(response, redirect, message)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  onInvoiceSaved(response: any, redirect: string, message: string) {

    if (message != null)
      this.showMessage(message);

    if (redirect != null)
      this.router.navigate([redirect]);

  }

  getCustomers(id: number): Observable<any> {

    var url = this.apiBaseUrl + 'Organizations/' + id.toString() + '/Customers';

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  createCustomer(organizationId: number, company: ICompany): Observable<any> {

    var c = JSON.stringify(company);
    var url = this.apiBaseUrl + 'Organizations/' + organizationId.toString() + '/Customers';

    return this.http.post<any>(url, c, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          this.handleError('createCompany', c)
        ));
  }

  updateCustomer(organizationId: number, company: ICompany): Observable<any> {

    var c = JSON.stringify(company);
    var url = this.apiBaseUrl + 'Organizations/' + organizationId.toString() + '/Customers';

    return this.http.put<any>(url, c, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          this.handleError('updateCompany', c)
        ));
  }

  createCustomerContact(organizationId: number, customerId: number, contact: IContact): Observable<any> {

    var c = JSON.stringify(contact);
    var url = this.apiBaseUrl + 'Organizations/' + organizationId.toString() + '/Customers/' + customerId.toString() + '/Contacts';

    console.log(url);

    return this.http.post<any>(url, c, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          this.handleError('adduser', c)
        ));
  }

  updateCustomerContact(organizationId: number, customerId: number, contact: IContact): Observable<any> {

    var c = JSON.stringify(contact);
    var url = this.apiBaseUrl + 'Organizations/' + organizationId.toString() + '/Customers/' + customerId.toString() + '/Contacts/' + contact.Id.toString();

    return this.http.put<any>(url, c, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          this.handleError('adduser', c)
        ));
  }

  showMessage(message: string) {
    let config = new MatSnackBarConfig();
    config.duration = 7000;
    this.snackBar.open(message, undefined, config);
  }
  
    onFinished(response: any)
    {
        console.log(response);
    }
    
    setHttpOptions() {
        var bearerToken = 'bearer ' + this.authenticationService.token;

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
    }
}

