
<style>
  .time-row, .text-input {
    font-size: 16px;
    margin-left: 0px !important;
    margin-bottom: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    border: 1px solid gray;
  }

  .mat-dialog-content {
     max-height: none;
  }

  .mat-form-field-label {
    margin-left: 10px !important;
  }

  textarea:focus, input:focus {
    caret-color: black;
  }

  /*::ng-deep .mat-form-field-underline {
    display: none;
  }*/

  textarea
  {
    margin-bottom: 0px;
  }

  .amount {
    height: 43px;
    margin-top: 0px;
  }
  .delete_button {
    float: right;
    cursor: pointer;
  }

  .time-col {
    padding-top:5px;
    display: inline;
    margin-left: 5px;
  }

  .time-input
  {
    width: 70px;
  }
  .left-margin
  {
    margin-left:25px;
  }

  mat-select.select-hours, mat-select.select-vat {
    padding: 9px !important;
    border: 1px solid gray !important;
  }

</style>


<!--<h1 mat-dialog-title *ngIf="!isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Redigera kund</h1>-->

<div mat-dialog-content class="edit-invoicegroup-popup">
  <form #personForm="ngForm" novalidate>


    <div class="row" style="margin-left:0px;margin-right:0px;">
      <mat-form-field appearance="outline" style="width:100%;">
        <mat-label>Beskriv ditt arbete</mat-label>
        <textarea matInput [disabled]="!canEditGroup()" [ngModel]="data.invoiceItemGroup.Description" (ngModelChange)="data.invoiceItemGroup.Description = $event; quickRowValidate()" required md-maxlength="500" rows="3" placeholder="Beskrivning av utfört arbete" id="message" name="description-quick-add" md-select-on-focus></textarea>
      </mat-form-field>
      <!--<mat-form-field class="col-sm-12">
        <textarea matInput autofocus placeholder="Beskriv ditt arbete, denna information inkluderas på fakturan" [(ngModel)]="data.invoiceItemGroup.Description" ngDefaultControl [ngModelOptions]="{standalone: true}"></textarea>
      </mat-form-field>-->
    </div>
    <div class="row">
      <div class="col-3">
        <h5>Belopp (kr)</h5>
        <input matInput type="number" class="text-input amount" [disabled]="!canEditGroup()" [(ngModel)]="data.invoiceItemGroup.Amount" [ngModelOptions]="{ standalone: true }">
      </div>

      <div class="col-lg-6" *ngIf="canEditGroup()">

        <h5>Arbetad tid/dag:</h5>

        <mat-select placeholder="Timmar" class="select-hours col-6" [(ngModel)]="hoursSelected" name="hoursworked">
          <mat-option *ngFor="let item of hoursList" value="{{item?.Value}}">{{item?.Value}} h</mat-option>
        </mat-select>

        <mat-select placeholder="Minuter" class="select-hours col-6" [(ngModel)]="minutesSelected" name="minutesworked">
          <mat-option *ngFor="let item of minutesList" value="{{item?.Value}}">{{item?.Value}} min</mat-option>
        </mat-select>

      </div>
      <div *ngIf="customerIsSweden && !isAdmin" class="col-lg-3">
        <h5>Momssats</h5>
        <mat-select [disabled]="!canEditGroup()" placeholder="Moms" class="select-vat" [(ngModel)]="data.invoiceItemGroup.Vat" name="vat" style="width:80px;" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let item of vatList" value="{{item?.Value}}">{{item?.Value}} %</mat-option>
        </mat-select>
      </div>
      <div *ngIf="customerIsSweden || isAdmin" class="col-lg-3">
        <h5>Momssats</h5>
        <mat-select [disabled]="!canEditGroup() && !isAdmin" placeholder="Moms" class="select-vat" [(ngModel)]="data.invoiceItemGroup.Vat" name="vat" style="width:80px;" [ngModelOptions]="{standalone: true}"> 
          <mat-option *ngFor="let item of vatList" value="{{item?.Value}}">{{item?.Value}} %</mat-option>
        </mat-select>
      </div>
      <div class="col-lg-3" style="margin-bottom: 15px; font-size: 80%; float: right;">
        <mat-checkbox matInput [(ngModel)]="data.invoiceItemGroup.ReverseTax" (change)="reverseTaxChange(data.invoiceItemGroup, $event)" [ngModelOptions]="{standalone: true}">
          Omvänd skattskyldighet/byggmoms
        </mat-checkbox>
      </div>
    </div>
    
    <div *ngIf="timeReportStatuses && data.invoiceItemGroup.TimeReportStatus" class="row" style="margin-right:0px;margin-left:0px;">
      <h5>Visa på faktura:</h5>
      <mat-select [disabled]="!canEditGroup()" placeholder="Tid på fakturan" class="select-hours select-time-report-status" [(ngModel)]="data.invoiceItemGroup.TimeReportStatus.Id" (selectionChange)="timeReportStatusChange($event)" name="timeReportStatus" ngDefaultControl [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let item of timeReportStatuses" value="{{item?.Id}}">{{item?.DisplayName}}</mat-option>
      </mat-select>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <mat-calendar class="fixed-calendar" [selected]="selectedDates" (selectedChange)="calendarSelect($event)" #datepicker ngDefaultControl></mat-calendar>
      </div>
      <div class="col-sm-6">

      </div>
    </div>

    <div *ngFor="let i of data.invoiceItemGroup.InvoiceRows" style="width:100%;margin-left:5px;margin-bottom:18px;">

      <div class="time-col">
        <span><b>{{i.StartDate | date: 'yyyy-MM-dd'}}</b></span>
      </div>
      <div class="time-col left-margin">

        <mat-select [disabled]="!canEditGroup() && i.Id > 0" placeholder="Timmar" [(ngModel)]="i.HoursWorked" [ngModelOptions]="{standalone: true}" class="time-input" style="width: 52px;">
          <mat-option *ngFor="let item of hoursList" value="{{item?.Value}}">{{item?.Value}} h</mat-option>
        </mat-select>

      </div>
      <div class="time-col" style="margin-left:10px;">

        <mat-select [disabled]="!canEditGroup() && i.Id > 0" class="time-input" placeholder="Minuter" [(ngModel)]="i.MinutesWorked" name="minutesworked" [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let item of minutesList" value="{{item?.Value}}">{{item?.Value}} min</mat-option>
        </mat-select>

      </div>
      <div style="float:right;">

        <mat-icon *ngIf="canEditGroup() || i.Id.length > 20" (click)="deleteListItem(i)" class="delete_button time-input" style="width:20px;">delete</mat-icon>

      </div>
    </div>


    <div *ngIf="data.invoiceItemGroup?.Type?.Name == 'Övrigt'">
      <div class="row">
        <div class="col-sm-6">
          <h5>Belopp (kr)</h5>
          <input matInput class="text-input" type="number" [(ngModel)]="data.invoiceItemGroup.Amount" ngDefaultControl [ngModelOptions]="{standalone: true}">
        </div>
        <div class="col-sm-6">
          <h5>Momssats</h5>
          <mat-select placeholder="Moms" class="select-vat" [(ngModel)]="data.invoiceItemGroup.Vat" name="rowvatselected">
            <mat-option *ngFor="let item of vatList" value="{{item?.Value}}">{{item?.Value}}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="row" style="margin-top:25px;margin-bottom:25px;">
        <div class="col-sm-12">
          <textarea matInput [ngModel]="data.invoiceItemGroup.Description" (ngModelChange)="data.invoiceItemGroup.Description = $event" required md-maxlength="1000" rows="5" placeholder="Beskrivning" id="description" name="description" ngDefaultControl [ngModelOptions]="{standalone: true}" md-select-on-focus></textarea>
        </div>
      </div>

    </div>
  </form>

  <div class="row">
    <div class="col-lg-12" style="color:red; margin-bottom:5px;">
      <span>{{ErrorMessageSend}}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <button mat-stroked-button *ngIf="canEditGroup()" align="left" color="secondary" style="width:22%" (click)="deleteRow()">Radera</button>&nbsp;
      <button mat-stroked-button *ngIf="canEditGroup()" style="width:32%" (click)="cancel()">Avbryt</button>&nbsp; <!-- *ngIf="!data.invoiceItemGroup.isNew"-->
      <button mat-raised-button [disabled]="!validates()" color="accent" style="width:32%" (click)="saveRow()">Spara</button>&nbsp;

    </div>
  </div>

</div>

