import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle } from '@angular/material';

import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
//import { ServiceService } from '../../core/services/ServiceService';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { ICompany } from '../../core/interfaces/ICompany';
import { IContact } from '../../core/interfaces/IContact';
import { OrganizationService } from '../../core/services/OrganizationService';
import { CompanyService } from '../../core/services/CompanyService';

@Component({
    selector: 'edit-contact-dialog',
    template: `
<h2 mat-dialog-title *ngIf="isNew">Ny kontakt</h2>
<h2 mat-dialog-title *ngIf="!isNew">Redigera kontakt</h2>
<mat-dialog-content>

<form>
        
    <mat-form-field  style="width:100%;">
        <input matInput type="text" pattern="[a-öA-Ö_&-]+([ ]?[a-öA-Ö_&-]+)*" [(ngModel)]='data.reference.Name' placeholder="Namn" required name="name">
    </mat-form-field>
    <mat-form-field  style="width:100%;">
        <input matInput type="email" pattern="[A-Öa-ö0-9._%+-]+@[A-Öa-ö0-9.-]+\.[A-Öa-ö]{1,63}$" [(ngModel)]='data.reference.Email' placeholder="Epost" required name="Email">
    </mat-form-field>    
    <mat-form-field  style="width:100%;">
        <input matInput type="text" [(ngModel)]='data.reference.Phone' placeholder="Telefon" required name="Phone">
    </mat-form-field>
    
</form>

</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">Stäng</button>
  <button mat-raised-button color="accent" (click)="save(data.reference)">
      &nbsp;Spara&nbsp;
  </button>
<p style="width: 100%; font-size: 80%; color: red; margin-top: 15px;">
{{ErrorMessageDesc}}
</p>
  
</div>


`
})


export class EditContactDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public reference: IContact;
    private companyId: number;
    public isNew: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    quickrowValid: boolean = false;
  ErrorMessageDesc: string = '';
  currentUserOrganizationId: number = 0;
        
    constructor(
        public dialogRef: MatDialogRef<EditContactDialogComponent>,
        private http: Http,
      private authenticationService: AuthenticationService,
      private organizationService: OrganizationService,
        private companyService: CompanyService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        
    }

  ngOnInit() {
    
    if (this.data.reference == undefined)
      this.data.reference = {} as IContact;    
    
    this.companyId = this.data.companyId;
    
    if (this.data.reference != null && this.data.reference.Id != undefined && this.data.reference.Id > 0)
    {
        this.reference = JSON.parse(JSON.stringify(this.data.reference));
    }
    else
    {
        this.data.contact = <IContact>{};
    }

    this.isNew = (this.data.reference.Id == undefined || this.data.reference.Id == 0) ? true : false;

    if (this.authenticationService.getCurrentUserOrganization != null) {
      this.currentUserOrganizationId = this.authenticationService.getCurrentUserOrganization().Id;
    }
  }

  save(contact: IContact)
  {
    this.validateForm();
    this.emailValidate();

    if (this.validateForm() && this.emailValidate()) {
      
      this.ErrorMessageDesc = '';

      if (this.currentUserOrganizationId > 0) {
        if (contact.Id == undefined || contact.Id == 0) {

          this.organizationService.createCustomerContact(this.currentUserOrganizationId, this.companyId, contact)
            .subscribe(value => this.onAfterRowSaved(value),
              error => this.onError(error)
            );
        }
        else {
          
          this.organizationService.updateCustomerContact(this.currentUserOrganizationId, this.companyId, contact)
            .subscribe(value => this.onAfterRowSaved(value),
              error => this.onError(error)
            );
        }
      }
      else {
        if (contact.Id == undefined || contact.Id == 0) {

          this.companyService.addUser(contact, this.companyId)
            .subscribe(value => this.onAfterRowSaved(value),
              error => this.onError(error)
            );
        }
        else {

          this.companyService.updateUser(contact, this.companyId)
            .subscribe(value => this.onAfterRowSaved(value),
              error => this.onError(error)
            );
        }
      }
    }
        
  }

  validateForm() {
    
    if (this.data.reference.Name == null || this.data.reference.Name == "") {
      this.ErrorMessageDesc = 'Du har glömt att fylla i namnet'
      return false;
    }
    else if (this.data.reference.Phone == null || this.data.reference.Phone == "") {
      this.ErrorMessageDesc = 'Du har glömt att fylla i telefonnummer'
      return false;
    }
    else {
      this.ErrorMessageDesc = '';
      return true;
    }

  }

  public emailValidate() {

    var regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    var inputIsValid = regex.test(this.data.reference.Email);
    
    if (inputIsValid) {
      this.quickrowValid = true;
      this.ErrorMessageDesc = '';
      return true;
    }
    else {
      this.quickrowValid = false;
      this.ErrorMessageDesc = 'Epostadressen är ogiltig. Kontrollera att du har skrivit rätt.';
      return false;
    }

  }

    cancel() {
        this.dialogRef.close(undefined);
    }

    onError(error: any)
    {
        alert('An error occured');
        console.log(error);
    }

    onAfterRowSaved(response: ICompany)
    {
        this.dialogRef.close(response);
    }
    
}
